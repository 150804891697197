import React from 'react';
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    img: '/img/5days_r3.png',
    text1: '5DaysHouse',
    text2: '极速打造您梦想中的家'
  },
  content: {
    className: 'banner0-content',
    children: [
      'Build your',
      'dream home quickly'
    ],
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>业务介绍</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                '/img/planning.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '快装修',
            },
            { name: 'content', children: '全程托管式服务,在您的预算内,不需要您出面,15个工作日内完成装修施工' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                '/img/tools.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '局部改造',
            },
            {
              name: 'content',
              children: '让您居住在房产内的同时，以最短的时间完成局部改造',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                '/img/agreement.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '房产管家',
            },
            {
              name: 'content',
              children: '面向房产内的设施需要的长期维护、维修、更换的客户',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '案例展示', className: 'title-h1' },
      // {
      //   name: 'content',
      //   className: 'title-content',
      //   children: '在这里用一段话介绍服务的案例情况',
      // },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/1.png',
          },
          content: { children: '木质的简约' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/2.png',
          },
          content: { children: '现代极简' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/3.png',
          },
          content: { children: '藏在卧室的书房' },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/4.png',
          },
          content: { children: '光感大平层' },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/5.png',
          },
          content: { children: '地中海弧线' },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/6.png',
          },
          content: { children: '后现代融合' },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/7.png',
          },
          content: { children: '直男的法式浪漫' },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              '/img/showcases/8.png',
          },
          content: { children: '阳光盥洗室' },
        },
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 12,
        className: 'block',
        title: {
          className: 'logo',
          children:
            '/img/5days_a3.png',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (<span>5days looks forward to working with you.<br /> 5days 期待与您合作。</span>),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 12,
        className: 'block',
        title: { children: '联系我们' },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'mailto:info@5dayshouse.com',
              children: '邮箱：info@5dayshouse.com'
            },
            {
              name: 'link1',
              href: 'tel:13911359665',
              children: '电话：(86)13911359665'
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        ©2023 by 5DaysHouse All Rights Reserved
        <div>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502052314" target="_blank">
            <img src='/img/beian.png' />
            京公网安备11010502052314号
          </a>
          <a href="https://beian.miit.gov.cn" target="_blank"> 京ICP备2023010591号-1 </a>
        </div>
      </div>
    ),
  },
};
