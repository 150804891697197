import React from 'react';
import { Button, Col, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { isImg } from './utils';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource, isMobile } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const slogan = isMobile ? (<Row justify="end" className='title-text3'>
      {dataSource.content.children[0]}
      {dataSource.content.children[1]}
    </Row>) : <Row justify="end" className='title-text3'>
      {dataSource.content.children[0]}
      <br />
      {dataSource.content.children[1]}
    </Row>;
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <Row key="title" justify="center" {...dataSource.title}>
            <Col className='logo'>
              <img src={dataSource.title.img} width="200" alt="img" />
            </Col>
            <Col className='title-wrapper'>
              <Row className='title-text1'>{dataSource.title.text1}</Row>
              <Row className='title-text2'>{dataSource.title.text2}</Row>
              <Row justify="end" className='title-text3'>
                {slogan}
              </Row>
            </Col>
          </Row>
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
